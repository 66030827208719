.localContainer {
	.localCamConfigContainer {
		position: absolute;
		left: 3px;
		bottom: 7px;

		.localCamConfig {
			z-index: 20;
			position: absolute;
			transition: all 0.3s ease;
		}

		.hoverOutline {
			position: absolute;
			z-index: 24;
			border: 2px dashed #ffffff;
			width: calc(100% - 4px);
			height: calc(100% - 4px);
			top: 0;
			background: rgba(0, 0, 0, 0.5);
		}

		.localVideo {
			position: absolute;
			width: 100%;
			height: 100%;
			top: 0;
			border-radius: 4px;
			background: grey;
			display: flex;
			justify-content: center;
			align-items: center;
			.localLoading {
				border: 3px solid transparent;
				border-top: 3px solid white;
				border-bottom: 3px solid white;
				border-right: 3px solid white;
				border-radius: 50%;
				width: 0.7vh;
				height: 0.7vh;
				animation: spin 2s linear infinite;
				display: flex;
				justify-content: center;
				align-items: center;
				z-index: 27;
			}
		}

		.manipIconContainer {
			z-index: 25;
			width: calc(100% - 4px);
			height: calc(100% - 4px);
			top: 0;
			display: flex;
			position: absolute;
			justify-content: center;
			align-items: center;
			background: rgba(0, 0, 0, 0.5);
			border: 2px dashed #ffffff;
		}

		.manipIcon {
		}
	}
}
